import {graphql} from 'gatsby';
import Img from 'gatsby-image';
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import growingUp from '../assets/images/growing_up.svg';
import startupImage from '../assets/images/startup_2.svg';
import teamwork from '../assets/images/team_work.svg';
import Layout from '../components/layout';

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const Careers = ({data}) => {
  const [images, setImages] = useState([]);
  const {careers} = data;
  const careerImages = careers.edges.filter(image => image.node.relativePath.indexOf('careers') > -1);

  useEffect(() => {
    const shuffledImages = shuffleArray([...careerImages]);
    setImages(shuffledImages);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Careers</title>
        <meta name="description" content="Careers @ Black Cape" />
      </Helmet>
      <section id="banner" className="careers">
        {data.file && (
          <Img
            fluid={data.file.childImageSharp.fluid}
            style={{
              width: '100%',
              height: '100%',
              maxHeight: '600px',
              position: 'fixed',
              top: '0',
              zIndex: '0'
            }}
          />
        )}
        <div className="inner">
          <header className="major">
            <h1>Careers</h1>
          </header>
          <div className="content">
            <p>Don the Cape!</p>
            <ul className="actions vertical">
              <li>
                <a
                  href="https://blackcape.applytojob.com/apply/"
                  className="button special fit"
                  target="_blank"
                  rel="noreferrer"
                >
                  Apply
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div style={{overflowX: 'auto', overflowY: 'hidden', width: '100%'}}>
        <div
          className="grid-wrapper"
          style={{
            display: 'grid',
            background: '#222',
            gridGap: '0rem',
            gridTemplateColumns: 'repeat(1000, 0fr)'
          }}
        >
          {images.map((image, index) => (
            <div key={`image-${index}`} style={{height: '200px', width: '400px'}}>
              <Img fluid={image.node.childImageSharp.fluid} style={{height: '200px', width: '400px'}} />
            </div>
          ))}
        </div>
      </div>
      <div id="main" style={{background: 'linear-gradient(180deg, #7a00ea, #222)'}}>
        <section id="one">
          <div className="inner">
            <p>
              Black Cape is passionate about software engineering and we’re looking for full stack software engineers
              that share our enthusiasm. We want to talk to you if you get excited about:
            </p>
            <ul>
              <li>Working on mission critical projects that have a real impact</li>
              <li>Crafting UIs that astonish users</li>
              <li>Using python to analyze data or create webapps</li>
              <li>Combining massive data sets to unlock new insights</li>
              <li>Writing infrastructure as code to make DevOps a breeze</li>
              <li>Using the open source tools to continually level up</li>
              <li>Collaborating with end users design solutions</li>
              <li>Building teams that continually raise the bar</li>
              <li>Sharing your expertise with teammates and learning from others</li>
            </ul>
            <p>Here are the tools that we commonly use:</p>
            <ul>
              <li>Core Languages: Python / Java / JavaScript / TypeScript / Go</li>
              <li>JS Frameworks: React / Angular / Vue</li>
              <li>Web Mapping: MapboxGL/deck.gl, Leaflet, OpenLayers, Cesium</li>
              <li>Machine Learning: TensorFlow / Scikit-learn / NLTK</li>
              <li>Data Storage: PostgreSQL / PostGIS / Elasticsearch / Arango</li>
              <li>Cloud: AWS / Azure</li>
              <li>Tools: Docker / Ansible / SaltStack / GitLab CI / Jenkins</li>
            </ul>
            <h3>A Day in the Life</h3>
            <p style={{display: 'inline-block'}}>
              <span className="image left">
                <img src={teamwork} alt="Teamwork" />
              </span>
              We believe good teams and continual learning are core to delivering extraordinary results. Our teams are
              typically 4-8 full-stack engineers that fully own the software development lifecycle and the project
              management process. Teams come up with agile processes that work for them rather than strict adherence to
              any one standard. Engineers are responsible for everything from requirements gathering and sprint planning
              to UI development, backend coding, testing, DevOps, and deployment. On a daily basis you’ll be having a
              standup with your team, holding design sessions to work through tough issues, or planning the next sprint.
            </p>
            <h3>Treat Engineers Like Professionals</h3>
            <p style={{display: 'inline-block'}}>
              <span className="image left">
                <img src={startupImage} alt="Start Up" />
              </span>
              We believe in treating our engineers like professionals. That means providing engineers with top of the
              line MacBook Pro, creating policies that allow flexible work locations, and time off policies that make
              sense. That also means that we want engineers to use the latest tools and techniques to generate game
              changing results for our customers. Our customers look to us for innovative solutions and as engineers we
              need to constantly sharpen our skills while adding new tricks to our bag.
            </p>
            <h3>Known, liked, and trusted</h3>
            <p style={{display: 'inline-block'}}>
              <span className="image left">
                <img src={growingUp} alt="Growing Up" />
              </span>
              At the end of the day, we want to build a company where people have colleagues that they know, like, and
              trust, work on meaningful projects, and have opportunities to learn and grow.
            </p>
            <p>
              Please send us an email at{' '}
              <a target="_top" href="mailto:careers@blackcape.io">
                careers@blackcape.io
              </a>{' '}
              to learn more.
            </p>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    file(relativePath: {eq: "spotlight1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    careers: allFile(filter: {extension: {regex: "/(jpg|png)/"}}) {
      edges {
        node {
          publicURL
          name
          relativePath
          childImageSharp {
            fluid(maxHeight: 400, maxWidth: 400, fit: CONTAIN, background: "#222") {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default Careers;
